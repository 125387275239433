import React, { Component, useLayoutEffect, useRef } from 'react';
import CategoryList from './CategoryList';
import Summary from './Summary';
import { Scroll } from '@react-three/drei';

function MenuController({ menue: initialMenu }) {

    const [currentCategoryId, setCurrentCategoryId] = React.useState(null);
    const [menue, setMenue] = React.useState(initialMenu);

    function registerChoice(category, dish) {
        const newMenue = menue.map(c =>
            c.id != category.id
                ? c
                : {
                    ...category,
                    dishes: category.dishes.map(d =>
                        dish.id !== d.id ? d : { ...d, liked: !d.liked }
                    )
                }
        );
        setMenue(newMenue);
    }

    function resetChoices(category) {
        const newMenue = menue.map((c) => c.id != category.id
            ? c
            : {
                ...category,
                dishes: category.dishes.map(({ liked, ...dish }) => ({ ...dish, liked: false }))
            }
        );
        setMenue(newMenue);
    }

    function setCurrentCategory(category) {
        if (category.id != currentCategoryId) {
            setCurrentCategoryId(category.id);
        }
        else {
            unsetCurrentCategory();
        }
    }

    function unsetCurrentCategory() {
        setCurrentCategoryId(null);
    }

    return (<Scroll html>
        <div className='menue-container'>
            <div className='menue'>
                <CategoryList
                    menue={menue}
                    setCurrentCategory={setCurrentCategory}
                    currentCategoryId={currentCategoryId}
                    registerChoice={registerChoice}
                    resetChoices={resetChoices}
                />
                <Summary
                    menue={menue}
                    registerChoice={registerChoice}
                />
            </div>
        </div>
    </Scroll>);
}

export default MenuController;