import React, { Suspense, useRef, useState, CSSProperties } from "react";
import { useControls } from "leva";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Model as Model } from "./World";
import { Bamboo } from "../../Bamboo";
import ClipLoader from "react-spinners/ClipLoader";

import {
  Stage,
  OrbitControls,
  Bounds,
  PerspectiveCamera,
  GizmoHelper,
  GizmoViewport,
  Lightformer,
  Environment,
  ArcballControls,
  ScrollControls,
} from "@react-three/drei";
import MenuController from "../MenuPage/MenuController";
import { BounceLoader, ClimbingBoxLoader, PacmanLoader } from "react-spinners";

function Soup({ menue }) {
  var { color } = useControls({
    color: "#fff2d2",
  });

  var scaleBamboo = 0.4;

  return (
    <div className="soup" style={{ background: color }}>
      <Suspense
        fallback={
          <div className="loading-indicator">
            <BounceLoader
              className="bouncer"
              color="#63251d"
              loading={true}
              size={400}
            />
          </div>
        }
      >
        <Canvas shadows>
          <ScrollControls pages={3} damping={0.15}>
            <ambientLight />
            <pointLight position={[0, 10, 10]} intensity={4.5} />
            <group>
              <ScrollControls pages={3} damping={0.15}></ScrollControls>

              <PerspectiveCamera
                name="Camera"
                makeDefault={true}
                far={100}
                near={0.1}
                fov={22.9}
                position={[20.22, 4.19, 6.81]}
                rotation={[-0.28, 1.26, 0.26]}
              />
              <Model />
              <Bamboo
                scale={[scaleBamboo, scaleBamboo, scaleBamboo]}
                position={[-11, 0.5, -5]}
                rotation={[-0.28, 0.8, 0.26]}
              />
            </group>
            <MenuController menue={menue}></MenuController>
          </ScrollControls>
        </Canvas>
      </Suspense>
    </div>
  );
}

function Loading() {
  return <h2> Loading alibaba ...</h2>;
}

export default Soup;
