import React, { Component } from 'react';
import CategorySummary from './CategroySummary';
import Category from './Category';



function CategoryList({ menue, setCurrentCategory, currentCategoryId, registerChoice, resetChoices }) {

    function onActivate(category) {
        setCurrentCategory(category)
    }

    return <div>
        {menue.map((category) => currentCategoryId != category.id ?
            <CategorySummary key={category.id} category={category} onActivate={onActivate} /> :
            <Category
                key={category.id}
                category={category}
                registerChoice={registerChoice}
                resetChoices={resetChoices}
                onActivate={onActivate}
            />)}
    </div>;
}

export default CategoryList;