import React, { Component } from 'react';
import Dish from './Dish';

function Category({category, registerChoice, resetChoices, onActivate}) {

    const totalVotes = category.dishes.reduce((prev, curr) => {
        if(curr.liked){
            return prev + 1;
        } else {
            return prev;
        }
    }, 0);


    return <div>
        <div className='container'>
            <div className='Category row'>
                <h1 className='CategoryTitel' onClick={() => onActivate(category)}>{category.titel}
                </h1>
            </div>
            <div className='Dishes row'>
                {category.dishes.map(dish =>
                <Dish key={dish.id}
                    dnumber={dish.dnumber}
                    title={dish.title}
                    description={dish.description}
                    price={dish.price}
                    liked={dish.liked}
                    onClickHandler={() => registerChoice(category, dish)}>
                </Dish>
                )}
            </div>

        </div>
    </div>;
}

export default Category;