/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 ../public/world.glb
*/

import React, { useLayoutEffect, useRef, useState } from 'react'
import { useGLTF, PerspectiveCamera, useAnimations, useScroll, ScrollControls  } from '@react-three/drei'
import { useFrame,useThree  } from '@react-three/fiber'
import { gsap } from 'gsap'
import { easing } from 'maath'

export function Model(props) {
  const group = useRef();
  const tl = useRef();
  const bowl = useRef();
  const mouseResponse = useRef();
  const { viewport } = useThree()

  const { nodes, materials, animations } = useGLTF('/world.glb');

  const scroll = useScroll();

  useFrame((state, delta) => {
    tl.current.seek(scroll.offset * tl.current.duration());
    easing.dampE(mouseResponse.current.rotation, [state.pointer.y / 40, -state.pointer.x / 4, 0], 0.1, delta)
  });

  useLayoutEffect(() => {

    tl.current = gsap.timeline();

    tl.current.to(
      bowl.current.rotation,
      {
        duration: 5.5,
        y: -Math.PI / .5
      },
      0
    );

    tl.current.to(
      bowl.current.position,
      {
        duration: 5.5,
        y: .5,
        z: 2
      },
      0
    )
  }, []);

  return (
    <group ref={group} {...props} dispose={null} >
      
      <group name="Scene">
        <PerspectiveCamera name="Camera" makeDefault={true} far={100} near={0.1} fov={22.9} position={[20.22, 4.19, 6.81]} rotation={[-0.28, 1.26, 0.26]} />
        <group ref={mouseResponse}>
          <group ref={bowl} >
            <mesh name="Bowl" geometry={nodes.Bowl.geometry} material={materials['Material.003']} position={[0.15, -0.04, 0.1]} rotation={[0.02, -0.08, -0.13]} scale={[1.01, 0.13, 1.01]} />
            <mesh name="Noodles" geometry={nodes.Noodles.geometry} material={materials['Material.004']} position={[0.07, 1.41, -0.13]} />
            <mesh name="Noodles001" geometry={nodes.Noodles001.geometry} material={materials['Material.004']} position={[1.06, 1.34, -0.07]} />
            <mesh name="Noodles002" geometry={nodes.Noodles002.geometry} material={materials['Material.004']} position={[0.88, 0.92, 0.05]} />
            <mesh name="Noodles003" geometry={nodes.Noodles003.geometry} material={materials['Material.004']} position={[-0.51, 1.5, 0.13]} />
            <mesh name="Lauch001" geometry={nodes.Lauch001.geometry} material={materials.Material} position={[1.18, 2.33, -0.34]} rotation={[0.49, -0.21, 0.34]} scale={[0.22, 0.12, 0.22]} />
            <mesh name="Lauch" geometry={nodes.Lauch.geometry} material={materials.Material} position={[0.02, 2.03, 0.37]} rotation={[-0.53, 0.43, -0.25]} scale={[0.19, 0.1, 0.19]} />
            <mesh name="Soup001" geometry={nodes.Soup001.geometry} material={materials['Material.001']} position={[0.19, 0.48, 0.05]} scale={[5.98, 8.98, 5.98]} />
            <mesh name="Soup" geometry={nodes.Soup.geometry} material={materials['Material.002']} position={[0.26, 0.82, 0.08]} rotation={[0, 0, -0.15]} />
            <mesh name="Noodles004" geometry={nodes.Noodles004.geometry} material={materials['Material.004']} position={[0.95, 0.83, 0.35]} />
            <mesh name="Noodles005" geometry={nodes.Noodles005.geometry} material={materials['Material.004']} position={[0.25, 1.46, -0.71]} rotation={[0, -0.94, 0]} />
            <mesh name="Noodles006" geometry={nodes.Noodles006.geometry} material={materials['Material.004']} position={[0.89, 0.92, 0.14]} rotation={[1.34, 0.49, 0.56]} />
            <mesh name="Chopsticks001" geometry={nodes.Chopsticks001.geometry} material={materials['Material.005']} position={[-0.37, 3.38, 1.36]} rotation={[0.39, 0.1, 0.06]} scale={-0.78} />
            <mesh name="Chopsticks" geometry={nodes.Chopsticks.geometry} material={materials['Material.005']} position={[1.18, 3.85, -1.98]} rotation={[-3.06, 0.68, -0.88]} scale={-0.78} />
            <group name="Egg" position={[-0.22, 1.22, 1.24]} rotation={[-2.58, -0.49, 0.02]} scale={[0.47, 0.6, 0.47]}>
              <mesh name="Sphere" geometry={nodes.Sphere.geometry} material={materials.EggWhite} />
              <mesh name="Sphere_1" geometry={nodes.Sphere_1.geometry} material={materials.EggYellow} />
            </group>
            <mesh name="Lauch002" geometry={nodes.Lauch002.geometry} material={materials.Material} position={[0.74, 0.74, 0.75]} rotation={[-1, 0.08, -0.1]} scale={[0.22, 0.12, 0.22]} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/world.glb')
