import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Soup from './pages/Soup/Soup'
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import Overlay from './pages/Overlay/Overlay';


const category = {
  id: "1",
  titel: "Starter",
  description: "Description of my first category.",
  dishes: [
    { id: "11", dnumber: 8, title: 'UMAMI TATAR', description: 'Gebeiztes Rindfleisch von Bioland-Fleischerei Fricke, Yuzu Kusho* Mayonnaise, Aji Tamago (Bauckhof-Ei in Soja-Mirin-Marinade), Reis Chip, Umami Salz*', price: 17.90, liked: false },
    { id: "12", dnumber: 3, title: 'GRILLED CHEESE SANDWICH', description: 'Weizenbrot von Bäcker Gaues, gegrillt mit Cheddar, geschmortem Schweinebauch, geschmorten Shiitake-Pilzen, gefriergetrockneten Tomaten, Shiitake-Miso-Mayonnaise und Schnittlauch', price: 9.90, liked: false },
  ]
};

const category2 = {
  id: "2",
  titel: "Main",
  description: "Description of my first category.",
  dishes: [
    { id: "21", dnumber: 82, title: 'LAMM MAZEMEN* TANTANMEN', description: 'Hausgemachte Panda-Style Udon-Nudeln, cremige Sauce aus goldener Sesampaste und Erdnuss, gewürzt mit Szechuan-Pfeffer, koreanischem Sam Jang und fermentierten Habaneros, dazu geschmortes Lamm- und Rinderhackfleisch mit orientalischen Gewürzen, Kimchi und Frühlinsgzwiebeln', price: 17.90, liked: false },
    { id: "22", dnumber: 32, title: 'BROWNBUTTER PARMESAN MAZEMEN', description: 'Hausgemachte Panda-Style Ramen-Nudeln, braune Butter, frisch geriebener Parmesan, reichlich Nori-Algen und Sesam, kräftige Brühe, Umami-Yuzu-Sojasauce und ein Hauch Lesser-Panda-Chiliöl, dazu geschmorte Shiitake-Pilze, Frühlingszwiebeln und ein Aji Tamago (Bauckhof-Ei in Soja-Mirin-Marinade)', price: 16.90, liked: false },
    { id: "23", dnumber: 42, title: 'VEGANE SHIITAKE DASHI', description: 'Brühe aus Kombu-Algen, Shiitake-Pilzen und Sojamilch, dazu fermentiertes Gemüse der Saison, Radieschensprossen, Frühlingszwiebeln, geschmorte Champignons, fermentiertes Kimchi, Nori-Sansho-Pfeffer-Öl und japanischer, goldener Sesam ', price: 15.90, liked: false }
  ]
};

const category4 = {
  id: "4",
  titel: "Drinks",
  description: "Description of my first category.",
  dishes: [
    { id: "41", dnumber: 82, title: 'Limonade Lavendel Weißer Pfirsich', description: 'hausgemacht aus echten Blüten und Gewürzen', price: 5.90, liked: false },
    { id: "42", dnumber: 32, title: 'Limonade Ingwer Galgant', description: 'hausgemacht aus echten Blüten und Gewürzen', price: 5.90, liked: false },
    { id: "43", dnumber: 42, title: 'Limonade Kamille Birne', description: 'hausgemacht aus echten Blüten und Gewürzen', price: 5.90, liked: false }
  ]
};

const menue = [category, category2, category4];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div>
      <div>
        <Soup menue={menue}></Soup>
        {/* <Overlay/> */}
      </div>
    </div>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
