import React, { Component } from 'react';
import Dish from './Dish';


function Summary({ menue, registerChoice }) {
    return <div style={{ "marginTop": "15em" }} className='container'>
        <div className='row'>
        {
                menue.reduce((prevCat, currCat) => prevCat + currCat.dishes.reduce((prev, curr) => prev + curr.liked, 0) , 0)  > 0 ?
                <h1 >Summary</h1> : ""
        }
        </div>
        <div>
            {
                menue.map(
                    category =>
                        category.dishes.reduce((prev, curr) => prev + curr.liked, 0) > 0 ?
                            <div key={category.id}>
                                <h3>{category.titel}</h3>
                                {
                                    category.dishes.map(dish =>
                                        dish.liked ?
                                            <Dish key={dish.id}
                                                dnumber={dish.dnumber}
                                                title={dish.title}
                                                description={dish.description}
                                                price={dish.price}
                                                liked={dish.liked}
                                                onClickHandler={() => registerChoice(category, dish)}>
                                            </Dish> : ""
                                    )
                                }
                            </div> : ""
                )
            }
        </div>
    </div>;
}

export default Summary;