import React from "react";
import "./Dish.css"

function Dish({ dnumber, title, description, price, liked, onClickHandler}) {


    const className = liked ? "material-symbols-sharp filled" : "material-symbols-sharp";

    return <div className="dish row" onClick={onClickHandler}>
            <div className="dish-number col-md-1">{dnumber}</div>
            <div className="dish-title col-md-3">{title}</div>
            <div className="dish-description col-md-6">{description}</div>
            <div className="dish-price col-md-1">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price)}</div>
            <div className="dish-likes col-md-1"><span className={className}>favorite</span></div>
        </div>;
}

export default Dish;