import React, { Component } from 'react';


function Overlay() {

    function togglePropperties() {
        const element = document.getElementById('leva__root');
        if(element.style.visibility === 'hidden'){
            element.style.visibility = 'visible'
        } else {
            element.style.visibility = 'hidden'
        }
    }


    return (<div className='overlay' onClick={togglePropperties}>
        <span className='material-symbols-sharp'>settings</span>
    </div>  );
}

export default Overlay;