/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 ../public/bamboo.glb
*/

import React, { useLayoutEffect, useRef } from 'react'
import { useGLTF, useAnimations, useScroll } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { gsap } from 'gsap'

export function Bamboo(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/bamboo.glb')
  const { actions } = useAnimations(animations, group)
  const { viewport } = useThree()
  const tl = useRef();
  const lamp = useRef();
  const scroll = useScroll();


  useFrame((state, delta) => {
    // tl.current.seek(scroll.offset * tl.current.duration());

    actions['Lamp Swing'].play();
    actions['Rope Swing'].play();

  });

  useLayoutEffect(() => {

    // tl.current = gsap.timeline();

    // tl.current.to(
    //   lamp.current.position,
    //   {
    //     duration: 5.5,
    //     x: -11,
    //     y: .5,
    //     z: -5
    //   },
    //   0
    // )
  }, []);

  return (
    <group ref={group} {...props} dispose={null} scale={[.3, .3, .3]} position={[9, 0.4, -1]}>
      <group name="Scene">
        <group ref={lamp} name="Empty" rotation={[0, 0.61, 0]}>
          <group name="Lamp_animation" position={[-6.57, 13.59, 5.43]} rotation={[0, 0.56, 0]}>
            <primitive object={nodes.Bone} />
          </group>
          <group name="NurbsCircle" position={[-5.41, -0.09, 3.36]} rotation={[0, 0.56, 0]} />
          <group name="Rope_animation" position={[-6.4, 16.89, 5.49]} rotation={[0, 0.56, 0]}>
            <primitive object={nodes.Bone_1} />
            <primitive object={nodes.neutral_bone} />
          </group>
          <group name="Mast" position={[0, 5.98, 0]} rotation={[0, 0.56, 0]}>
            <mesh name="Cube" geometry={nodes.Cube.geometry} material={materials['Bamboo Stamm']} />
            <mesh name="Cube_1" geometry={nodes.Cube_1.geometry} material={materials['Bamboo Stütze']} />
            <mesh name="Cube_2" geometry={nodes.Cube_2.geometry} material={materials['Bamboo Arm']} />
          </group>
          <mesh name="Rope001" geometry={nodes.Rope001.geometry} material={materials['Material.005']} position={[0, 15.16, 0]} rotation={[0, 0.56, 0]} />
          <group name="Lamp">
            <skinnedMesh name="Cylinder001" geometry={nodes.Cylinder001.geometry} material={materials['Material.003']} skeleton={nodes.Cylinder001.skeleton} />
            <skinnedMesh name="Cylinder001_1" geometry={nodes.Cylinder001_1.geometry} material={materials['Material.004']} skeleton={nodes.Cylinder001_1.skeleton} />
            <skinnedMesh name="Cylinder001_2" geometry={nodes.Cylinder001_2.geometry} material={materials['Material.004']} skeleton={nodes.Cylinder001_2.skeleton} />
          </group>
          <group name="Rope">
            <skinnedMesh name="NurbsPath" geometry={nodes.NurbsPath.geometry} material={materials['Material.005']} skeleton={nodes.NurbsPath.skeleton} />
            <skinnedMesh name="NurbsPath_1" geometry={nodes.NurbsPath_1.geometry} material={materials['Material.006']} skeleton={nodes.NurbsPath_1.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/bamboo.glb')
