import React, { Component } from 'react';


function CategorySummary({ category, onActivate }) {

    return <div className='container'>
        <div className='Category row' onClick={() => onActivate(category)}>
            <h1 className='CategoryTitel'>{category.titel}
            </h1>
        </div>
    </div>;
}

export default CategorySummary;